.hero_section {
  width: 100%;
}

.main_div {
  position: relative;
  height: 100%;
  padding-top: 10vw;
  padding-bottom: 400px;
  background: #2c2d35;
  z-index: 0;
}

.text_div {
  width: 100%;
  margin: auto;
  margin-top: 75px;
  text-align: center;
  z-index: 100;
}

.headline {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 73px;
  /* or 133% */

  text-align: center;

  color: #ffffff;
}

.subheadline {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  padding-top: 32px;
  line-height: 171%;

  text-align: center;

  color: #ffffff;
}

.button {
  margin-top: 48px;
  z-index: 100;
}

.vector {
  position: absolute;
  top: 40px;
  left: -210px;
  z-index: -10;
}

.vector2 {
  position: absolute;
  bottom: -10px;
  right: 0;
  z-index: -10;
}

.card_row {
  margin-top: 135px;
  width: 85%;
  margin-left: 10%;
}

.icon_container {
  margin-bottom: 35px;
}

.card_headline {
  margin-bottom: 32px;
}

.copy {
  text-align: left;
}

@media screen and (max-width: 1800px) {
  .main_div {
    padding-bottom: 25vw;
  }
}

@media screen and (max-width: 1440px) {
  .subheadline {
    font-size: 20px;
    width: 75%;
    margin: auto;
  }
}

@media screen and (max-width: 1280px) {
  .vector2 {
    width: 30vw;
  }
}

@media screen and (max-width: 992px) {
  .text_div {
    width: 85%;
  }

  .headline {
    line-height: 85%;
  }

  .subheadline {
    width: 100%;
  }

  .vector {
    width: 30vw;
    left: -15%;
  }

  .vector2 {
    width: 30vw;
  }
}

@media screen and (max-width: 800px) {
  .headline {
    margin-top: 55px;
  }
}

@media screen and (max-width: 576px) {
  .main_div {
    padding-top: 24px;
    padding-bottom: 200px;
  }

  .vector {
    width: 30vw;
    left: -15%;
  }

  .vector2 {
    width: 30vw;
  }

  .headline {
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    /* or 127% */
    margin: auto;
    margin-top: 60px;
    width: 65%;

    text-align: center;

    color: #ffffff;
  }

  .subheadline {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    padding-top: 40px;
    width: 100%;

    text-align: center;

    color: #ffffff;
  }

  .text_div {
    margin: auto;
    padding-left: 0;
  }

  .card_row {
    margin-top: 32px;
  }

  .col {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 432px) {
  .main_div {
    padding-bottom: 150px;
  }

  .headline {
    width: 85%;
    margin: auto;
  }
}
